import React from "react";
import searchIcon from "../assets/images/search-lg.svg";
import close from "../assets/images/x-close.svg";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { toast } from "react-toastify";
const Sidebar = ({
  sideInputRef,
  setSearch,
  search,
  openSidebar,
  setOpenSidebar,
}) => {
  const navigate = useNavigate();

  const handleSearch = () => {
    if (search?.length > 2) {
      setOpenSidebar(false);
      navigate(`/search/${search}`);
    } else {
      toast.info("Search Parameter must be 3 or more characters");
    }
  };
  return (
    <div className={`sidebar ${!openSidebar ? "hide" : null}`}>
      <div className="side-links">
        <div className="links">
          <div className="link">
            <Link onClick={() => setOpenSidebar(false)} to={"news"}>
              NEWS
            </Link>
          </div>
          <div className="link">
            <Link onClick={() => setOpenSidebar(false)} to={"matches"}>
              MATCHES
            </Link>
          </div>
          <div className="link">
            <Link onClick={() => setOpenSidebar(false)} to={"players"}>
              PLAYERS
            </Link>
          </div>
          {/* <div className="link">
            <Link onClick={() => setOpenSidebar(false)} to={"tickets"}>
              TICKETS
            </Link>
          </div> */}
          <div className="link">
            <Link onClick={() => setOpenSidebar(false)} to={"club-info"}>
              CLUB INFORMATION
            </Link>
          </div>
          {/* <div className="link">
						<Link onClick={() => setOpenSidebar(false)} to={"shop"}>
							SHOP
						</Link>
					</div> */}
          <div className="link">
            <Link onClick={() => setOpenSidebar(false)} to={"membership"}>
              MEMBERSHIP
            </Link>
          </div>
          <div className="link">
            <Link onClick={() => setOpenSidebar(false)} to={"sportingtv"}>
              SPORTING TV
            </Link>
          </div>
          {/* <div className="link">
            <Link
              onClick={() => setOpenSidebar(false)}
              to={"club-info/foundingmembers"}
            >
              FOUNDING MEMBER
            </Link>
          </div> */}
        </div>
        <h2>Search</h2>
        <div className="search-input">
          <div className={"active"}>
            <img src={searchIcon} alt="" />
            <input
              type="text"
              placeholder="Search Sporting"
              value={search}
              ref={sideInputRef}
              onChange={(e) => setSearch(e?.target?.value)}
              className="input"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
            {search?.length > 0 && (
              <div className="close" onClick={() => setSearch("")}>
                <img src={close} alt="" />
              </div>
            )}
          </div>
        </div>
        <div className="social">
          <h4>FOLLOW SPORTING</h4>
          <div className="icon">
            <a
              onClick={() => setOpenSidebar(false)}
              href="https://www.google.com"
              rel="noreferrer"
              target={"_blank"}
            >
              <FaFacebook />
            </a>
            <a
              onClick={() => setOpenSidebar(false)}
              href="https://www.google.com"
              rel="noreferrer"
              target={"_blank"}
            >
              <FaTwitter />
            </a>
            <a
              onClick={() => setOpenSidebar(false)}
              href="https://www.google.com"
              rel="noreferrer"
              target={"_blank"}
            >
              <AiFillInstagram />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
